/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/css/custom.css"
import "./src/css/normalize.css"

const portal = document.createElement("div")
portal.id = "portal"
document.body.appendChild(portal)
