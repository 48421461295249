exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-book-demo-tsx": () => import("./../../../src/pages/book-demo.tsx" /* webpackChunkName: "component---src-pages-book-demo-tsx" */),
  "component---src-pages-collector-tsx": () => import("./../../../src/pages/collector.tsx" /* webpackChunkName: "component---src-pages-collector-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customer-tsx": () => import("./../../../src/pages/customer.tsx" /* webpackChunkName: "component---src-pages-customer-tsx" */),
  "component---src-pages-employee-tsx": () => import("./../../../src/pages/employee.tsx" /* webpackChunkName: "component---src-pages-employee-tsx" */),
  "component---src-pages-insight-tsx": () => import("./../../../src/pages/insight.tsx" /* webpackChunkName: "component---src-pages-insight-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-templates-about-template-tsx-content-file-path-data-about-about-us-mdx": () => import("./../../../src/templates/about.template.tsx?__contentFilePath=/home/develop/pxplanding/data/about/about-us.mdx" /* webpackChunkName: "component---src-templates-about-template-tsx-content-file-path-data-about-about-us-mdx" */),
  "component---src-templates-about-template-tsx-content-file-path-data-about-approach-mdx": () => import("./../../../src/templates/about.template.tsx?__contentFilePath=/home/develop/pxplanding/data/about/approach.mdx" /* webpackChunkName: "component---src-templates-about-template-tsx-content-file-path-data-about-approach-mdx" */),
  "component---src-templates-about-template-tsx-content-file-path-data-about-benefit-mdx": () => import("./../../../src/templates/about.template.tsx?__contentFilePath=/home/develop/pxplanding/data/about/benefit.mdx" /* webpackChunkName: "component---src-templates-about-template-tsx-content-file-path-data-about-benefit-mdx" */),
  "component---src-templates-about-template-tsx-content-file-path-data-about-challenge-mdx": () => import("./../../../src/templates/about.template.tsx?__contentFilePath=/home/develop/pxplanding/data/about/challenge.mdx" /* webpackChunkName: "component---src-templates-about-template-tsx-content-file-path-data-about-challenge-mdx" */),
  "component---src-templates-about-template-tsx-content-file-path-data-about-solution-mdx": () => import("./../../../src/templates/about.template.tsx?__contentFilePath=/home/develop/pxplanding/data/about/solution.mdx" /* webpackChunkName: "component---src-templates-about-template-tsx-content-file-path-data-about-solution-mdx" */),
  "component---src-templates-faq-page-tsx-content-file-path-data-faq-faq-mdx": () => import("./../../../src/templates/faq.page.tsx?__contentFilePath=/home/develop/pxplanding/data/faq/faq.mdx" /* webpackChunkName: "component---src-templates-faq-page-tsx-content-file-path-data-faq-faq-mdx" */),
  "component---src-templates-industry-template-tsx": () => import("./../../../src/templates/industry.template.tsx" /* webpackChunkName: "component---src-templates-industry-template-tsx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-home-page-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/homePage.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-home-page-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-844-d-8-c-48-41-ee-4168-a-66-a-bd-29-d-897-e-5-de-deskless-employee-deerhurst-usecase-interview-watch-video-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/844d8c48-41ee-4168-a66a-bd29d897e5de-deskless-employee-deerhurst-usecase-interview-watch-video.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-844-d-8-c-48-41-ee-4168-a-66-a-bd-29-d-897-e-5-de-deskless-employee-deerhurst-usecase-interview-watch-video-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-844-d-8-c-48-41-ee-4168-a-66-a-bd-29-d-897-e-5-de-o-form-a-66-a-bd-29-d-897-e-5-de-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/844d8c48-41ee-4168-a66a-bd29d897e5de-o-form-a66a-bd29d897e5de.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-844-d-8-c-48-41-ee-4168-a-66-a-bd-29-d-897-e-5-de-o-form-a-66-a-bd-29-d-897-e-5-de-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-management-financial-services-es-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/customer-feedback-management-financial-services-es.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-management-financial-services-es-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-management-platform-by-pressnxpress-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/customer-feedback-management-platform-by-pressnxpress.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-management-platform-by-pressnxpress-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-management-retail-es-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/customer-feedback-management-retail-es.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-management-retail-es-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-management-retail-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/customer-feedback-management-retail.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-management-retail-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-solutions-survey-kiosks-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/customer-feedback-solutions-survey-kiosks.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customer-feedback-solutions-survey-kiosks-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customerfeedback-1-bookdemo-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/customerfeedback-1-bookdemo.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customerfeedback-1-bookdemo-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customerfeedback-1-contact-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/customerfeedback-1-contact.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customerfeedback-1-contact-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customerfeedback-2-bookdemo-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/customerfeedback-2-bookdemo.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customerfeedback-2-bookdemo-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customerfeedback-2-contact-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/customerfeedback-2-contact.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-customerfeedback-2-contact-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-deskless-employee-deerhurst-use-case-interview-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/deskless-employee-deerhurst-use-case-interview.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-deskless-employee-deerhurst-use-case-interview-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-dining-services-experience-improvement-with-real-time-feedback-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/dining-services-experience-improvement-with-real-time-feedback.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-dining-services-experience-improvement-with-real-time-feedback-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-feedback-survey-kiosk-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/feedback-survey-kiosk.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-feedback-survey-kiosk-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-gas-stations-convenience-stores-feedback-app-kiosk-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/gas-stations-convenience-stores-feedback-app-kiosk.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-gas-stations-convenience-stores-feedback-app-kiosk-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-measure-every-customer-experience-with-real-time-feedback-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/measure-every-customer-experience-with-real-time-feedback.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-measure-every-customer-experience-with-real-time-feedback-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-on-site-feedback-collection-with-physical-survey-kiosk-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/on-site-feedback-collection-with-physical-survey-kiosk.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-on-site-feedback-collection-with-physical-survey-kiosk-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-smiley-terminal-feedback-solution-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/smiley-terminal-feedback-solution.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-smiley-terminal-feedback-solution-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-survey-solutions-interactive-kiosks-customer-feedback-surveys-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/survey-solutions-interactive-kiosks-customer-feedback-surveys.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-survey-solutions-interactive-kiosks-customer-feedback-surveys-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-touchfree-bookdemo-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/touchfree-bookdemo.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-touchfree-bookdemo-mdx" */),
  "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-touchfree-contact-mdx": () => import("./../../../src/templates/new-templates/landings/landing.template.tsx?__contentFilePath=/home/develop/pxplanding/data/landings/touchfree-contact.mdx" /* webpackChunkName: "component---src-templates-new-templates-landings-landing-template-tsx-content-file-path-data-landings-touchfree-contact-mdx" */),
  "component---src-templates-new-templates-solutions-main-solutions-page-tsx-content-file-path-data-solutions-solution-mdx": () => import("./../../../src/templates/new-templates/solutions/main/solutions.page.tsx?__contentFilePath=/home/develop/pxplanding/data/solutions/solution.mdx" /* webpackChunkName: "component---src-templates-new-templates-solutions-main-solutions-page-tsx-content-file-path-data-solutions-solution-mdx" */),
  "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-employee-mdx": () => import("./../../../src/templates/new-templates/solutions/solution.template.tsx?__contentFilePath=/home/develop/pxplanding/data/solutions/employee.mdx" /* webpackChunkName: "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-employee-mdx" */),
  "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-healthcare-mdx": () => import("./../../../src/templates/new-templates/solutions/solution.template.tsx?__contentFilePath=/home/develop/pxplanding/data/solutions/healthcare.mdx" /* webpackChunkName: "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-healthcare-mdx" */),
  "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-hospitality-mdx": () => import("./../../../src/templates/new-templates/solutions/solution.template.tsx?__contentFilePath=/home/develop/pxplanding/data/solutions/hospitality.mdx" /* webpackChunkName: "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-hospitality-mdx" */),
  "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-property-mdx": () => import("./../../../src/templates/new-templates/solutions/solution.template.tsx?__contentFilePath=/home/develop/pxplanding/data/solutions/property.mdx" /* webpackChunkName: "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-property-mdx" */),
  "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-restaurant-mdx": () => import("./../../../src/templates/new-templates/solutions/solution.template.tsx?__contentFilePath=/home/develop/pxplanding/data/solutions/restaurant.mdx" /* webpackChunkName: "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-restaurant-mdx" */),
  "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-retail-mdx": () => import("./../../../src/templates/new-templates/solutions/solution.template.tsx?__contentFilePath=/home/develop/pxplanding/data/solutions/retail.mdx" /* webpackChunkName: "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-retail-mdx" */),
  "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-transportation-mdx": () => import("./../../../src/templates/new-templates/solutions/solution.template.tsx?__contentFilePath=/home/develop/pxplanding/data/solutions/transportation.mdx" /* webpackChunkName: "component---src-templates-new-templates-solutions-solution-template-tsx-content-file-path-data-solutions-transportation-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-45-retail-survey-questions-for-the-customer-feedback-you-need-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/45-retail-survey-questions-for-the-customer-feedback-you-need.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-45-retail-survey-questions-for-the-customer-feedback-you-need-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-a-guide-to-optimizing-retail-experience-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/a-guide-to-optimizing-retail-experience.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-a-guide-to-optimizing-retail-experience-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-calculating-the-roi-of-kiosk-based-feedback-for-enhanced-cx-and-operations-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/calculating-the-roi-of-kiosk-based-feedback-for-enhanced-cx-and-operations.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-calculating-the-roi-of-kiosk-based-feedback-for-enhanced-cx-and-operations-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-capturing-customer-feedback-at-franchise-and-restaurant-locations-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/capturing-customer-feedback-at-franchise-and-restaurant-locations.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-capturing-customer-feedback-at-franchise-and-restaurant-locations-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-collecting-feedback-in-a-socially-distanced-world-pressnxpress-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/collecting-feedback-in-a-socially-distanced-world-pressnxpress.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-collecting-feedback-in-a-socially-distanced-world-pressnxpress-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-connecting-the-dots-linking-customer-feedback-with-retail-metrics-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/connecting-the-dots-linking-customer-feedback-with-retail-metrics.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-connecting-the-dots-linking-customer-feedback-with-retail-metrics-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-create-a-culture-of-employee-engagement-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/create-a-culture-of-employee-engagement.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-create-a-culture-of-employee-engagement-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-experience-journey-map-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/customer-experience-journey-map.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-experience-journey-map-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-experience-metrics-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/customer-experience-metrics.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-experience-metrics-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-obsession-starts-with-perfecting-your-how-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/customer-obsession-starts-with-perfecting-your-how.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-obsession-starts-with-perfecting-your-how-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-satisfaction-in-the-competitive-retail-landscape-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/customer-satisfaction-in-the-competitive-retail-landscape.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-satisfaction-in-the-competitive-retail-landscape-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-service-vs-customer-experience-two-approaches-working-together-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/customer-service-vs-customer-experience-two-approaches-working-together.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-customer-service-vs-customer-experience-two-approaches-working-together-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-elevating-airport-experience-the-power-of-real-time-feedback-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/elevating-airport-experience-the-power-of-real-time-feedback.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-elevating-airport-experience-the-power-of-real-time-feedback-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-elevating-customer-experiences-the-power-of-real-time-feedback-kiosks-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/elevating-customer-experiences-the-power-of-real-time-feedback-kiosks.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-elevating-customer-experiences-the-power-of-real-time-feedback-kiosks-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-elevating-patient-care-with-interactive-satisfaction-feedback-kiosks-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/elevating-patient-care-with-interactive-satisfaction-feedback-kiosks.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-elevating-patient-care-with-interactive-satisfaction-feedback-kiosks-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-employee-engagement-leads-to-a-better-customer-experience-the-ideal-contextual-experience-for-your-business-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/employee-engagement-leads-to-a-better-customer-experience-the-ideal-contextual-experience-for-your-business.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-employee-engagement-leads-to-a-better-customer-experience-the-ideal-contextual-experience-for-your-business-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-employee-engagement-makes-sense-for-your-people-and-your-profit-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/employee-engagement-makes-sense-for-your-people-and-your-profit.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-employee-engagement-makes-sense-for-your-people-and-your-profit-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-24-7-convenience-store-operations-with-real-time-customer-feedback-solutions-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/enhancing-24-7-convenience-store-operations-with-real-time-customer-feedback-solutions.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-24-7-convenience-store-operations-with-real-time-customer-feedback-solutions-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-customer-feedback-management-with-pxp-a-comprehensive-solution-for-service-quality-improvement-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/enhancing-customer-feedback-management-with-pxp-a-comprehensive-solution-for-service-quality-improvement.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-customer-feedback-management-with-pxp-a-comprehensive-solution-for-service-quality-improvement-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-facility-services-with-touchless-feedback-kiosks-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/enhancing-facility-services-with-touchless-feedback-kiosks.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-facility-services-with-touchless-feedback-kiosks-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-hotel-guest-journeys-with-pressnxpress-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/enhancing-hotel-guest-journeys-with-pressnxpress.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-hotel-guest-journeys-with-pressnxpress-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-retail-operations-leveraging-customer-and-shopper-insights-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/enhancing-retail-operations-leveraging-customer-and-shopper-insights.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-enhancing-retail-operations-leveraging-customer-and-shopper-insights-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-ensuring-great-dining-experiences-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/ensuring-great-dining-experiences.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-ensuring-great-dining-experiences-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-facility-services-companies-need-customer-feedback-solution-to-succeed-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/facility-services-companies-need-customer-feedback-solution-to-succeed.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-facility-services-companies-need-customer-feedback-solution-to-succeed-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-fast-accurate-and-actionable-reports-are-key-to-guest-experience-success-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/fast-accurate-and-actionable-reports-are-key-to-guest-experience-success.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-fast-accurate-and-actionable-reports-are-key-to-guest-experience-success-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-feedback-in-action-in-the-hospitality-industry-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/feedback-in-action-in-the-hospitality-industry.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-feedback-in-action-in-the-hospitality-industry-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-five-questions-to-ask-yourself-when-building-a-customer-journey-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/five-questions-to-ask-yourself-when-building-a-customer-journey.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-five-questions-to-ask-yourself-when-building-a-customer-journey-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-five-signs-you-should-start-exploring-your-customer-experience-in-context-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/five-signs-you-should-start-exploring-your-customer-experience-in-context.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-five-signs-you-should-start-exploring-your-customer-experience-in-context-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-guidelines-for-measuring-service-quality-in-facility-services-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/guidelines-for-measuring-service-quality-in-facility-services.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-guidelines-for-measuring-service-quality-in-facility-services-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-harness-the-power-of-kiosk-based-feedback-to-improve-cx-and-operations-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/harness-the-power-of-kiosk-based-feedback-to-improve-cx-and-operations.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-harness-the-power-of-kiosk-based-feedback-to-improve-cx-and-operations-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-hospitality-feedback-in-context-matters-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/hospitality-feedback-in-context-matters.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-hospitality-feedback-in-context-matters-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-how-customer-empowerment-rules-the-hospitality-industry-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/how-customer-empowerment-rules-the-hospitality-industry.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-how-customer-empowerment-rules-the-hospitality-industry-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-how-customer-feedback-kiosks-like-pressnxpress-improve-hotel-guest-experiences-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/how-customer-feedback-kiosks-like-pressnxpress-improve-hotel-guest-experiences.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-how-customer-feedback-kiosks-like-pressnxpress-improve-hotel-guest-experiences-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-how-facility-services-can-utilize-customer-feedback-to-improve-their-operation-and-grow-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/how-facility-services-can-utilize-customer-feedback-to-improve-their-operation-and-grow.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-how-facility-services-can-utilize-customer-feedback-to-improve-their-operation-and-grow-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-how-patient-feedback-kiosks-enable-data-driven-healthcare-improvements-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/how-patient-feedback-kiosks-enable-data-driven-healthcare-improvements.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-how-patient-feedback-kiosks-enable-data-driven-healthcare-improvements-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-how-to-turn-negative-reviews-into-a-positive-improvement-to-your-customer-experience-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/how-to-turn-negative-reviews-into-a-positive-improvement-to-your-customer-experience.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-how-to-turn-negative-reviews-into-a-positive-improvement-to-your-customer-experience-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-importance-of-customer-satisfaction-measurement-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/importance-of-customer-satisfaction-measurement.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-importance-of-customer-satisfaction-measurement-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-importance-of-experience-monitoring-for-deskless-and-frontline-workers-using-feedback-kiosk-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/importance-of-experience-monitoring-for-deskless-and-frontline-workers-using-feedback-kiosk.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-importance-of-experience-monitoring-for-deskless-and-frontline-workers-using-feedback-kiosk-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-importance-of-experience-monitoring-in-facility-services-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/importance-of-experience-monitoring-in-facility-services.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-importance-of-experience-monitoring-in-facility-services-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-improve-service-quality-in-facility-services-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/improve-service-quality-in-facility-services.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-improve-service-quality-in-facility-services-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-increase-deskless-workforce-engagement-with-pressnxpress-feedback-kiosks-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/increase-deskless-workforce-engagement-with-pressnxpress-feedback-kiosks.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-increase-deskless-workforce-engagement-with-pressnxpress-feedback-kiosks-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-innovative-feedback-solutions-touchless-kiosks-in-the-catering-and-dining-sector-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/innovative-feedback-solutions-touchless-kiosks-in-the-catering-and-dining-sector.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-innovative-feedback-solutions-touchless-kiosks-in-the-catering-and-dining-sector-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-is-employee-net-promoter-score-enps-enough-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/is_employee_net_promoter_score_enps_enough.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-is-employee-net-promoter-score-enps-enough-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-it-is-time-to-ditch-the-long-surveys-and-use-real-time-micro-feedback-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/it-is-time-to-ditch-the-long-surveys-and-use-real-time-micro-feedback.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-it-is-time-to-ditch-the-long-surveys-and-use-real-time-micro-feedback-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-leveraging-customer-satisfaction-data-for-enhanced-retail-performance-the-power-of-pressnxpress-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/leveraging-customer-satisfaction-data-for-enhanced-retail-performance-the-power-of-pressnxpress.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-leveraging-customer-satisfaction-data-for-enhanced-retail-performance-the-power-of-pressnxpress-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-leveraging-generative-ai-to-enhance-customer-experience-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/leveraging-generative-ai-to-enhance-customer-experience.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-leveraging-generative-ai-to-enhance-customer-experience-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-leveraging-real-time-feedback-to-enhance-food-service-and-dining-experiences-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/leveraging-real-time-feedback-to-enhance-food-service-and-dining-experiences.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-leveraging-real-time-feedback-to-enhance-food-service-and-dining-experiences-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-optimizing-customer-experience-with-pressnxpress-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/optimizing-customer-experience-with-pressnxpress.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-optimizing-customer-experience-with-pressnxpress-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-patient-feedback-kiosks-improving-healthcare-experiences-and-operations-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/patient-feedback-kiosks-improving-healthcare-experiences-and-operations.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-patient-feedback-kiosks-improving-healthcare-experiences-and-operations-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-planning-your-hotel-guest-experience-touchpoints-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/planning-your-hotel-guest-experience-touchpoints.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-planning-your-hotel-guest-experience-touchpoints-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-pressnxpress-touchless-feedback-terminal-to-collect-feedback-anywhere-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/pressnxpress-touchless-feedback-terminal-to-collect-feedback-anywhere.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-pressnxpress-touchless-feedback-terminal-to-collect-feedback-anywhere-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-putting-your-employee-engagement-into-context-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/putting-your-employee-engagement-into-context.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-putting-your-employee-engagement-into-context-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-redefining-the-omnichannel-experience-approach-with-pressnxpress-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/redefining-the-omnichannel-experience-approach-with-pressnxpress.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-redefining-the-omnichannel-experience-approach-with-pressnxpress-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-retail-success-unlocking-operational-insights-with-customer-feedback-kiosks-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/retail-success-unlocking-operational-insights-with-customer-feedback-kiosks.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-retail-success-unlocking-operational-insights-with-customer-feedback-kiosks-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-revolutionize-your-customer-experience-with-real-time-feedback-analytics-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/revolutionize-your-customer-experience-with-real-time-feedback-analytics.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-revolutionize-your-customer-experience-with-real-time-feedback-analytics-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-revolutionizing-dining-services-with-real-time-customer-feedback-and-advanced-analytics-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/revolutionizing-dining-services-with-real-time-customer-feedback-and-advanced-analytics.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-revolutionizing-dining-services-with-real-time-customer-feedback-and-advanced-analytics-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-streamlining-multi-site-property-management-for-optimal-performance-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/streamlining-multi-site-property-management-for-optimal-performance.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-streamlining-multi-site-property-management-for-optimal-performance-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-taking-care-of-your-deskless-workforce-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/taking-care-of-your-deskless-workforce.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-taking-care-of-your-deskless-workforce-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-critical-role-of-customer-feedback-for-convenience-stores-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-critical-role-of-customer-feedback-for-convenience-stores.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-critical-role-of-customer-feedback-for-convenience-stores-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-importance-of-customer-feedback-in-brick-and-mortar-retail-business-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-importance-of-customer-feedback-in-brick-and-mortar-retail-business.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-importance-of-customer-feedback-in-brick-and-mortar-retail-business-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-importance-of-customer-feedback-in-enhancing-customer-experience-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-importance-of-customer-feedback-in-enhancing-customer-experience.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-importance-of-customer-feedback-in-enhancing-customer-experience-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-importance-of-patient-experience-in-healthcare-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-importance-of-patient-experience-in-healthcare.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-importance-of-patient-experience-in-healthcare-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-power-of-feedback-kiosks-in-healthcare-enhancing-patient-care-and-satisfaction-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-power-of-feedback-kiosks-in-healthcare-enhancing-patient-care-and-satisfaction.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-power-of-feedback-kiosks-in-healthcare-enhancing-patient-care-and-satisfaction-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-revolutionary-touchfreexpress-feedback-kiosk-transforming-customer-feedback-collection-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-revolutionary-touchfreexpress-feedback-kiosk-transforming-customer-feedback-collection.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-revolutionary-touchfreexpress-feedback-kiosk-transforming-customer-feedback-collection-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-rise-of-just-in-time-feedback-methods-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-rise-of-just-in-time-feedback-methods.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-rise-of-just-in-time-feedback-methods-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-roi-of-deskless-employee-feedback-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-roi-of-deskless-employee-feedback.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-roi-of-deskless-employee-feedback-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-silent-majority-valuing-deskless-workers-in-todays-workforce-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-silent-majority-valuing-deskless-workers-in-todays-workforce.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-silent-majority-valuing-deskless-workers-in-todays-workforce-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-the-value-of-frictionless-customer-experience-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/the-value-of-frictionless-customer-experience.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-the-value-of-frictionless-customer-experience-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-touchfree-feedback-kiosk-why-it-is-important-and-how-it-works-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/touchfree-feedback-kiosk-why-it-is-important-and-how-it-works.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-touchfree-feedback-kiosk-why-it-is-important-and-how-it-works-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-unlocking-the-potential-of-feedback-how-to-transform-ratings-and-service-quality-in-hospitality-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/unlocking-the-potential-of-feedback-how-to-transform-ratings-and-service-quality-in-hospitality.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-unlocking-the-potential-of-feedback-how-to-transform-ratings-and-service-quality-in-hospitality-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-using-real-time-customer-feedback-with-contextual-data-to-improve-customer-experience-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/using-real-time-customer-feedback-with-contextual-data-to-improve-customer-experience.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-using-real-time-customer-feedback-with-contextual-data-to-improve-customer-experience-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-utilizing-customer-feedback-to-improve-in-store-shopping-experiences-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/utilizing-customer-feedback-to-improve-in-store-shopping-experiences.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-utilizing-customer-feedback-to-improve-in-store-shopping-experiences-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-vice-president-of-operations-and-guest-experience-at-shargo-hotels-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/vice-president-of-operations-and-guest-experience-at-shargo-hotels.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-vice-president-of-operations-and-guest-experience-at-shargo-hotels-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-what-is-csat-and-how-is-it-measured-in-pressnxpress-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/what-is-csat-and-how-is-it-measured-in-pressnxpress.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-what-is-csat-and-how-is-it-measured-in-pressnxpress-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-who-we-are-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/who-we-are.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-who-we-are-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-why-understanding-the-context-of-your-customers-experience-matters-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/why-understanding-the-context-of-your-customers-experience-matters.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-why-understanding-the-context-of-your-customers-experience-matters-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-why-you-should-build-your-customer-journey-with-touchpoints-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/why-you-should-build-your-customer-journey-with-touchpoints.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-why-you-should-build-your-customer-journey-with-touchpoints-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-data-blog-your-customer-experience-management-needs-automation-mdx": () => import("./../../../src/templates/post.template.tsx?__contentFilePath=/home/develop/pxplanding/data/blog/your-customer-experience-management-needs-automation.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-data-blog-your-customer-experience-management-needs-automation-mdx" */),
  "component---src-templates-privacy-policy-page-tsx-content-file-path-data-privacy-policy-privacy-policy-mdx": () => import("./../../../src/templates/privacy-policy.page.tsx?__contentFilePath=/home/develop/pxplanding/data/privacy_policy/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-privacy-policy-page-tsx-content-file-path-data-privacy-policy-privacy-policy-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-contacthub-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/contacthub.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-contacthub-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-dashboard-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/dashboard.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-dashboard-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-email-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/email.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-email-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-feedbackterminal-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/feedbackterminal.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-feedbackterminal-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-inapp-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/inapp.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-inapp-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-integration-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/integration.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-integration-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-qrchip-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/qrchip.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-qrchip-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-sms-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/sms.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-sms-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-social-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/social.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-social-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-textanalytics-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/textanalytics.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-textanalytics-mdx" */),
  "component---src-templates-product-template-tsx-content-file-path-data-products-voice-mdx": () => import("./../../../src/templates/product.template.tsx?__contentFilePath=/home/develop/pxplanding/data/products/voice.mdx" /* webpackChunkName: "component---src-templates-product-template-tsx-content-file-path-data-products-voice-mdx" */),
  "component---src-templates-roles-template-tsx-content-file-path-data-roles-cx-professional-mdx": () => import("./../../../src/templates/roles.template.tsx?__contentFilePath=/home/develop/pxplanding/data/roles/cx-professional.mdx" /* webpackChunkName: "component---src-templates-roles-template-tsx-content-file-path-data-roles-cx-professional-mdx" */),
  "component---src-templates-roles-template-tsx-content-file-path-data-roles-data-analyst-mdx": () => import("./../../../src/templates/roles.template.tsx?__contentFilePath=/home/develop/pxplanding/data/roles/data-analyst.mdx" /* webpackChunkName: "component---src-templates-roles-template-tsx-content-file-path-data-roles-data-analyst-mdx" */),
  "component---src-templates-roles-template-tsx-content-file-path-data-roles-human-resources-employee-mdx": () => import("./../../../src/templates/roles.template.tsx?__contentFilePath=/home/develop/pxplanding/data/roles/human-resources-employee.mdx" /* webpackChunkName: "component---src-templates-roles-template-tsx-content-file-path-data-roles-human-resources-employee-mdx" */),
  "component---src-templates-roles-template-tsx-content-file-path-data-roles-human-resources-mdx": () => import("./../../../src/templates/roles.template.tsx?__contentFilePath=/home/develop/pxplanding/data/roles/human-resources.mdx" /* webpackChunkName: "component---src-templates-roles-template-tsx-content-file-path-data-roles-human-resources-mdx" */),
  "component---src-templates-roles-template-tsx-content-file-path-data-roles-marketing-mdx": () => import("./../../../src/templates/roles.template.tsx?__contentFilePath=/home/develop/pxplanding/data/roles/marketing.mdx" /* webpackChunkName: "component---src-templates-roles-template-tsx-content-file-path-data-roles-marketing-mdx" */),
  "component---src-templates-roles-template-tsx-content-file-path-data-roles-operations-mdx": () => import("./../../../src/templates/roles.template.tsx?__contentFilePath=/home/develop/pxplanding/data/roles/operations.mdx" /* webpackChunkName: "component---src-templates-roles-template-tsx-content-file-path-data-roles-operations-mdx" */),
  "component---src-templates-terms-and-conditions-page-tsx-content-file-path-data-terms-and-conditions-terms-and-conditions-mdx": () => import("./../../../src/templates/terms-and-conditions.page.tsx?__contentFilePath=/home/develop/pxplanding/data/termsAndConditions/TermsAndConditions.mdx" /* webpackChunkName: "component---src-templates-terms-and-conditions-page-tsx-content-file-path-data-terms-and-conditions-terms-and-conditions-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-app-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/banking/app.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-app-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-atm-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/banking/atm.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-atm-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-call-center-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/banking/call-center.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-call-center-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-counter-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/banking/counter.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-counter-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/banking/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-informationdesk-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/banking/informationdesk.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-informationdesk-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-waitingroom-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/banking/waitingroom.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-waitingroom-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/banking/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-banking-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-class-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/colleges/class.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-class-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-customer-service-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/colleges/customer-service.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-customer-service-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-parking-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/colleges/parking.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-parking-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-residence-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/colleges/residence.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-residence-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-restaurant-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/colleges/restaurant.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-restaurant-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-retail-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/colleges/retail.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-retail-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/colleges/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-wellbeing-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/colleges/wellbeing.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-colleges-wellbeing-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-app-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/entertainment/app.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-app-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-coatcheck-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/entertainment/coatcheck.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-coatcheck-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-customer-service-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/entertainment/customer-service.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-customer-service-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/entertainment/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-foodcourt-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/entertainment/foodcourt.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-foodcourt-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-parking-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/entertainment/parking.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-parking-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-retailshop-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/entertainment/retailshop.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-retailshop-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/entertainment/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-entertainment-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-class-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/fitness-recreationcenter/class.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-class-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-customer-service-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/fitness-recreationcenter/customer-service.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-customer-service-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/fitness-recreationcenter/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-gym-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/fitness-recreationcenter/gym.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-gym-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-locker-room-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/fitness-recreationcenter/locker-room.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-locker-room-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-massage-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/fitness-recreationcenter/massage.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-massage-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-multipurpose-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/fitness-recreationcenter/multipurpose.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-multipurpose-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/fitness-recreationcenter/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-fitness-recreationcenter-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-callcenter-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/healthcare/callcenter.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-callcenter-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/healthcare/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-foodshop-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/healthcare/foodshop.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-foodshop-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-laboratory-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/healthcare/Laboratory.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-laboratory-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-reception-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/healthcare/Reception.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-reception-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-room-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/healthcare/Room.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-room-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-waitingroom-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/healthcare/waitingroom.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-waitingroom-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/healthcare/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-healthcare-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/hotelaccomodation/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-lobby-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/hotelaccomodation/lobby.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-lobby-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-pool-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/hotelaccomodation/pool.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-pool-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-reception-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/hotelaccomodation/reception.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-reception-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-restaurant-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/hotelaccomodation/restaurant.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-restaurant-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-room-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/hotelaccomodation/room.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-room-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-shop-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/hotelaccomodation/shop.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-shop-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/hotelaccomodation/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-hotelaccomodation-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-apartment-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/property/apartment.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-apartment-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-commonroom-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/property/commonroom.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-commonroom-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-concierge-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/property/concierge.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-concierge-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-elevator-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/property/elevator.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-elevator-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/property/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-management-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/property/management.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-management-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-parking-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/property/parking.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-parking-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-recreation-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/property/recreation.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-property-recreation-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-callcenter-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/publicsector/callcenter.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-callcenter-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-counter-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/publicsector/counter.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-counter-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/publicsector/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-informationdesk-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/publicsector/informationdesk.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-informationdesk-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-parking-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/publicsector/parking.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-parking-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-waitingroom-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/publicsector/waitingroom.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-waitingroom-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-washroom-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/publicsector/washroom.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-washroom-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/publicsector/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-publicsector-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-bar-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/restaurant/bar.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-bar-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-cashier-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/restaurant/cashier.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-cashier-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/restaurant/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-host-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/restaurant/host.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-host-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-parking-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/restaurant/parking.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-parking-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-table-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/restaurant/table.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-table-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-washroom-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/restaurant/washroom.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-washroom-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/restaurant/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-restaurant-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-app-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail/app.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-app-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-call-center-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail/call-center.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-call-center-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-cashier-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail/cashier.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-cashier-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-customer-service-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail/customer-service.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-customer-service-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-email-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail/email.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-email-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-department-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail-emp/department.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-department-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-email-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail-emp/email.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-email-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-emplyeearea-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail-emp/emplyeearea.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-emplyeearea-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-paycheck-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail-emp/paycheck.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-paycheck-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-phone-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail-emp/phone.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-phone-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-portal-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail-emp/portal.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-portal-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-shuttle-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail-emp/shuttle.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-shuttle-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-timeclock-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail-emp/timeclock.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-emp-timeclock-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-parking-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail/parking.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-parking-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/retail/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-retail-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-elevator-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/shoppingcenter/elevator.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-elevator-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-entrance-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/shoppingcenter/entrance.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-entrance-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-foodcourt-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/shoppingcenter/foodcourt.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-foodcourt-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-informationdesk-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/shoppingcenter/informationdesk.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-informationdesk-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-parking-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/shoppingcenter/parking.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-parking-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-restingarea-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/shoppingcenter/restingarea.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-restingarea-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-washroom-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/shoppingcenter/washroom.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-washroom-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/shoppingcenter/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-shoppingcenter-website-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-app-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/transportation/app.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-app-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-baggage-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/transportation/baggage.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-baggage-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-passport-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/transportation/passport.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-passport-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-security-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/transportation/security.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-security-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-shelter-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/transportation/shelter.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-shelter-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-ticketmachine-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/transportation/ticketmachine.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-ticketmachine-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-vehicle-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/transportation/vehicle.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-vehicle-mdx" */),
  "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-website-mdx": () => import("./../../../src/templates/touchpoint.template.tsx?__contentFilePath=/home/develop/pxplanding/data/industries/transportation/website.mdx" /* webpackChunkName: "component---src-templates-touchpoint-template-tsx-content-file-path-data-industries-transportation-website-mdx" */)
}

